import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import Seo from "../components/seo";

const philosophyPage = () => {
    return (
      <Layout>
         <Seo
        title="Jobs"
        description="You want to be part of the Heinrig family? Please get in contact."/>
        <div className="flex flex-col lg:flex-row justify-center items-center h-screen">
          <h1 className="lg:w-1/2 text-right text-6xl font-semibold mx-5">you lookin'<br/> for a job?</h1>
          <div className="lg:w-1/2 pt-10 lg:pt-0">
            <h3 className="lg:w-2/3 text-xl lg:text-2xl mx-5">
            we are just starting out and could definitely use some help. <br />
            but to be honest, we don't know where to begin. <br />
            if you want to join the heinrig family, <br /> please contact us <Link className="inlinelink" to="/contact">here</Link>.</h3>
          </div>
        </div>
      </Layout>
  )
}

export default philosophyPage